export const menuItems = [
    {
        id: 1,
        label: 'GENERAR LIGA FACTURA ELECTRÓNICA',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'GENERAR LIGA PEDIMIENTO',
        icon: 'ri-dashboard-line',
        link: '/pedimiento'
    },
    {
        id: 3,
        label: 'GENERAR LIGA PECEM',
        icon: 'ri-dashboard-line',
        link: '/pecem'
    },
]